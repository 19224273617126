import React, { createContext, useEffect } from 'react';

// eslint-disable-next-line no-shadow
export enum ESeverity {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success'
}

export interface ISnackbar {
  text: string | null;
  severity?: ESeverity;
  isOpen?: boolean;
}

export interface ISnackbarProvider {
  showSnackbar: (text: string, severity?: ESeverity) => void;
  closeSnackbar: () => void;
  snackbar: ISnackbar;
}
type ProviderProps = {
  children: React.ReactNode;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const SnackbarContext = createContext<ISnackbarProvider>(null);

export const SnackbarProvider = ({ children }: ProviderProps): JSX.Element => {
  const [snackbar, setSnackbar] = React.useState<ISnackbar>({
    severity: ESeverity.success,
    text: null,
    isOpen: false
  });

  const showSnackbar = (text: string, severity?: ESeverity) => {
    setSnackbar({
      text,
      severity: severity || ESeverity.success,
      isOpen: true
    });
  };

  const closeSnackbar = () => {
    setSnackbar({
      ...snackbar,
      text: null,
      isOpen: false
    });
  };

  useEffect(() => {
    if (snackbar?.isOpen === false) {
      setTimeout(() => {
        setSnackbar({
          ...snackbar,
          severity: ESeverity.success
        });
      }, 200);
    }
  }, [snackbar?.isOpen]);

  return (
    <SnackbarContext.Provider value={{ showSnackbar, closeSnackbar, snackbar }}>
      {children}
    </SnackbarContext.Provider>
  );
};

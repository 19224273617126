import { ICompanySummary } from 'components/Company/interfaces';
import { IDepartmentSummary } from 'components/ManageDepartment/interfaces';

import { IStorageItems, IUser } from './interfaces';

export const getStorageItems = (): IStorageItems => {
  const storageToken = localStorage.getItem('token');
  const storageTenant = localStorage.getItem('tenant');
  const storageCompany = localStorage.getItem('company');
  const storageDepartment = localStorage.getItem('department');

  return {
    storageToken,
    storageTenant,
    storageCompany,
    storageDepartment
  };
};

const parseJwt = (token: string): IUser => {
  return JSON.parse(atob(token.split('.')[1]));
};

export const parseStorageToken = (): IUser | null => {
  const { storageToken } = getStorageItems();

  if (!storageToken) return null;

  const data = parseJwt(storageToken);
  return data;
};

export const parseStorageCompany = (): ICompanySummary | null => {
  const storageCompany = localStorage.getItem('company');

  if (!storageCompany) return null;
  return JSON.parse(storageCompany);
};

export const parseStorageDepartment = (): IDepartmentSummary | null => {
  const storageDepartment = localStorage.getItem('department');

  if (!storageDepartment) return null;
  return JSON.parse(storageDepartment);
};

export const parseStorageTenant = (): string | null => {
  const storageTenant = localStorage.getItem('tenant');

  if (!storageTenant) return null;
  return storageTenant;
};

import React, { useContext } from 'react';

import { Snackbar as MuiSnackbar } from '@material-ui/core';
import { AlertTitle } from '@mui/material';
import Alert from '@mui/material/Alert';

import { SnackbarContext, ESeverity } from 'contexts/SnackbarContext';

const severityTranslated = {
  error: 'Erro',
  warning: 'Aviso',
  info: 'informação',
  success: 'Sucesso'
};

const Snackbar = (): JSX.Element => {
  const { snackbar, closeSnackbar } = useContext(SnackbarContext);

  const severity = snackbar.severity || ESeverity.success;

  return (
    <div>
      <MuiSnackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        style={{
          color: 'White'
        }}
        open={snackbar?.isOpen}
        onClose={closeSnackbar}
        message={snackbar?.text}
      >
        <Alert onClose={closeSnackbar} severity={severity} elevation={2}>
          <AlertTitle> {severityTranslated[severity]}</AlertTitle>
          {snackbar?.text}
        </Alert>
      </MuiSnackbar>
    </div>
  );
};

export default Snackbar;

import { createTheme } from '@material-ui/core/styles';
import { ptBR as corePtBR } from '@mui/material/locale';
import { ptBR } from '@mui/x-data-grid';

interface ITickeStatusColors {
  background: React.CSSProperties['color'];
  color: React.CSSProperties['color'];
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    opened: ITickeStatusColors;
    received: ITickeStatusColors;
    processing: ITickeStatusColors;
    approved: ITickeStatusColors;
    denied: ITickeStatusColors;
  }
  interface PaletteOptions {
    opened: ITickeStatusColors;
    received: ITickeStatusColors;
    processing: ITickeStatusColors;
    approved: ITickeStatusColors;
    denied: ITickeStatusColors;
  }
}

const theme = createTheme(
  {
    overrides: {
      MuiButton: {
        root: {
          height: '57px',
          minWidth: '200px'
        }
      }
    },
    palette: {
      primary: {
        light: '#006cca1b',
        main: '#1976d2',
        dark: '#1565c0',
        contrastText: '#fff'
      },
      secondary: {
        light: '#ffd9e4',
        main: '#e91e63',
        dark: '#b0003a',
        contrastText: '#fff'
      },
      background: {
        default: '#fff',
        paper: '#f7f7f7'
      },

      opened: {
        background: '#dde2ff',
        color: '#2646ff'
      },

      received: {
        background: '#f8d3ff',
        color: '#9c27b0'
      },
      processing: {
        background: '#eaeaea',
        color: '#5f5f5f'
      },

      approved: {
        background: '#c2ffc5',
        color: '#00a40e'
      },

      denied: {
        background: '#ffd4d4',
        color: '#ff0101'
      }
    },
    // opened: ITickeStatusColors;
    //   received: ITickeStatusColors;
    //   processing: ITickeStatusColors;
    //   approved: ITickeStatusColors;
    //   denied: ITickeStatusColors;
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
      }
    }
  },
  ptBR,
  corePtBR
);

export default theme;

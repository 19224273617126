/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';

import { ICompanySummary } from 'components/Company/interfaces';
import { IDepartmentSummary } from 'components/ManageDepartment/interfaces';

import {
  getStorageItems,
  parseStorageCompany,
  parseStorageDepartment,
  parseStorageTenant,
  parseStorageToken
} from './helper';
import {
  IAccountInfo,
  IAuthContext,
  IEmployeeInfo,
  IProfile,
  IProviderProps
} from './interfaces';

export const AuthContext = React.createContext<IAuthContext>({
  isAuthenticated: false,
  permissions: null,
  tenant: null,
  employeeInfo: null,
  userAccountInfo: null,
  loadEmployeeInfo: () => {},
  authenticate: () => {},
  loadProfile: () => {},
  logout: () => {}
});
AuthContext.displayName = 'AuthContext';

export const AuthProvider = ({ children }: IProviderProps): JSX.Element => {
  const { storageToken, storageTenant } = getStorageItems();

  const [isAuthenticated, setIsAuthenticated] = useState(!!storageToken);
  const [permissions, setPermissions] = useState<Omit<IProfile, 'name'> | null>(
    null
  );
  const [userAccountInfo, setUserAccountInfo] = useState<IAccountInfo | null>(
    null
  );
  const [employeeInfo, setEmployeeInfo] = useState<IEmployeeInfo | null>(null);

  const logout = () => {
    setIsAuthenticated(false);
    setPermissions(null);

    localStorage.clear();
  };

  const loadEmployeeInfo = () => {
    const tenant = parseStorageTenant();
    const company = parseStorageCompany();
    const department = parseStorageDepartment();

    if (tenant && company && department) {
      setEmployeeInfo({ tenant, company, department });
    }
  };

  const loadProfile = () => {
    const data = parseStorageToken();

    if (data) {
      const { id, name, username, isEmployee } = data;
      const { name: office, ...profile } = data.profile;

      setPermissions({ ...profile });
      setUserAccountInfo({ id, name, username, office, isEmployee });

      if (isEmployee) {
        loadEmployeeInfo();
      }
    }
  };

  const authenticate = (
    token: string,
    company: ICompanySummary,
    department: IDepartmentSummary,
    cnpj: string
  ) => {
    setIsAuthenticated(true);

    localStorage.setItem('token', token);
    localStorage.setItem('tenant', cnpj);
    localStorage.setItem('company', JSON.stringify(company));
    localStorage.setItem('department', JSON.stringify(department));

    loadProfile();
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        tenant: storageTenant,
        permissions,
        authenticate,
        employeeInfo,
        loadProfile,
        loadEmployeeInfo,
        userAccountInfo,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext => {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be within its context');
  }
  return context;
};

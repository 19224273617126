import React from 'react';

import { LicenseInfo } from '@mui/x-license';
// eslint-disable-next-line import/order
import { ThemeProvider } from '@material-ui/core';
import ReactDOM from 'react-dom';
import 'index.css';

import App from 'App';
import { SnackbarProvider } from 'contexts/SnackbarContext';
import theme from 'styles/theme';

import { AuthProvider } from './contexts/auth/AuthContext';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY as string);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

import React from 'react';

import { Box, Grid, CircularProgress } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';

import Snackbar from 'components/Snackbar/Snackbar';
import { useAuth } from 'contexts/auth/AuthContext';

const UnauthenticatedApp = React.lazy(
  () => import('./pages/index/UnauthenticatedApp')
);

const AuthenticatedApp = React.lazy(
  () => import('./pages/index/AuthenticatedApp')
);

const PageSpinner = () => {
  return (
    <Box mt={4}>
      <Grid container alignItems="center" justifyContent="center">
        <CircularProgress size={80} />
      </Grid>
    </Box>
  );
};

const App = (): JSX.Element => {
  const { isAuthenticated, loadProfile } = useAuth();
  React.useEffect(() => {
    loadProfile();
  }, []);
  return (
    <Router>
      <Snackbar />
      <React.Suspense fallback={<PageSpinner />}>
        {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </React.Suspense>
    </Router>
  );
};

export default App;
